import React from 'react'
import { Helmet } from 'react-helmet'
import { BaseLayout } from '../components'
import { Link } from 'gatsby'
import { ArrowBack } from '@styled-icons/material-rounded'

export default function Page() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>お問い合わせいただきましてありがとうございます</title>
        <meta name="og:title" content="お問い合わせいただきましてありがとうございます" />
      </Helmet>
      <BaseLayout>
        <h1>お問い合わせいただきましてありがとうございます</h1>

        <p>内容を確認してお返事いたします。</p>
        <div>
          <Link to="/">
            <ArrowBack height="1rem" />
            トップページに戻る
          </Link>
        </div>
      </BaseLayout>
    </>
  )
}
